"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CreditNoteActionEnum = void 0;
var CreditNoteActionEnum;
(function (CreditNoteActionEnum) {
  CreditNoteActionEnum["Create"] = "create";
  CreditNoteActionEnum["Allocate"] = "allocate";
  CreditNoteActionEnum["Archive"] = "archive";
  CreditNoteActionEnum["ViewList"] = "view:list";
})(CreditNoteActionEnum = exports.CreditNoteActionEnum || (exports.CreditNoteActionEnum = {}));
