"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WastageActionEnum = void 0;
var WastageActionEnum;
(function (WastageActionEnum) {
  WastageActionEnum["View"] = "view";
  WastageActionEnum["Create"] = "create";
  WastageActionEnum["Submit"] = "submit";
  WastageActionEnum["Delete"] = "delete";
  /**
   * @deprecated
   */
  // Draft = 'draft',
  // Update = 'update',
})(WastageActionEnum = exports.WastageActionEnum || (exports.WastageActionEnum = {}));
