"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PosIntegrationActionEnum = void 0;
var PosIntegrationActionEnum;
(function (PosIntegrationActionEnum) {
  PosIntegrationActionEnum["ManageTenants"] = "manage:tenants";
  PosIntegrationActionEnum["UpdateItemMapping"] = "update:mapping:item";
  PosIntegrationActionEnum["ImportSales"] = "create:import";
  PosIntegrationActionEnum["DeleteImport"] = "delete:import";
  PosIntegrationActionEnum["ProcessImport"] = "process:import";
})(PosIntegrationActionEnum = exports.PosIntegrationActionEnum || (exports.PosIntegrationActionEnum = {}));
