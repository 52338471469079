import { CountryAlpha2, Currency, IANATimezone, Language } from '@supy.api/dictionaries';

import { BranchRegion, BranchRegionApi, IdType } from '@supy/common';
import { Country } from '@supy/countries';

export enum InventoryCostingMethod {
  MovingAverage = 'moving-average',
  WeightedAverage = 'weighted-average',
}

export enum PushToInventoryMethod {
  Manual = 'Manual',
  Auto = 'Auto',
}

export interface SettingsApi {
  readonly id: string;
  readonly retailer: IdType;
  readonly country: SettingsCountryApi;
  readonly displayStockCount: ToggleDataApi<never>;
  readonly allowNegativeQuantity: ToggleDataApi<never>;
  readonly automaticInventoryPush: ToggleDataApi<never>;
  readonly notificationEmails?: string[];
  readonly allowModifyReceivingQuantity: ToggleDataApi<ModifyReceivingDataApi>;
  readonly allowModifyReceivingPrice: ToggleDataApi<ModifyReceivingDataApi>;
  readonly inventoryCosting: InventoryCostingMethod;
  readonly wastages: WastageTypeApi[];
  readonly recipeCategories: RecipeCategoryApi[];
  readonly branchRegions: BranchRegionApi[];
  readonly accountingCategories: AccountingCategoryApi[];
  readonly preventBackdatedUpdates: ToggleDataApi<never>;
  readonly preventOrderingOutOfStock: ToggleDataApi<never>;
  readonly hideCKStock: ToggleDataApi<never>;
  readonly manageChannelItemsPerLocation: ToggleDataApi<never>;
  readonly allowCKInvoice: ToggleDataApi<never>;
  readonly salesTypes: SalesType[];
  readonly mandatoryAccountingCategoryOnItem: ToggleDataApi<never>;
}

export interface RetailerSettings {
  readonly id: string;
  readonly receivingAverageCostOptions?: ReceivingAverageCostOptions;
  readonly taxes?: TaxRate[];
  readonly displayStockCount: boolean;
  readonly notificationEmail?: string;
  readonly alternateLanguage: ToggleDataOptionalValue<Language>;
  readonly country?: Country;
  readonly mandatoryAttachmentOnReceiving?: ToggleDataApi<never>;
  readonly salesTypes?: SalesType[];
  readonly branchRegions: BranchRegion[];
}

export interface RetailerSettingsSalesType {
  readonly id: string;
  readonly name: string;
  readonly code: string;
  readonly isDefault: string;
}

export interface UserSettings {
  readonly sendNewOrderEmail?: boolean;
  readonly preferredLanguage?: Language;
}

export interface SettingsCountryApi {
  readonly id: string;
  readonly code: CountryAlpha2;
  readonly name: string;
  readonly currency: Currency;
  readonly utcOffset: number;
  readonly ianaTimeZone: IANATimezone;
  readonly currencyPrecision: number;
}

export interface ToggleDataApi<T> {
  readonly enabled: boolean;
  readonly data?: T;
}

export interface ToggleDataOptionalValue<T> extends ToggleDataApi<T> {
  readonly value?: T;
}

export interface ModifyReceivingDataApi {
  readonly value: number;
}

export interface RecipeCategoryApi {
  readonly id: string;
  readonly name: string;
  readonly isActive: boolean;
  readonly children?: RecipeCategoryApi[];
  readonly parent: IdType | null;
}

export interface WastageTypeApi {
  readonly id: string;
  readonly name: string;
  readonly expense: boolean;
  readonly limit: number;
  readonly isActive: boolean;
  readonly isSalesWastage: boolean;
}

export interface UpdateSettingsRequest {
  readonly id: string;
  readonly notificationEmails?: string[];
  readonly displayStockCount?: ToggleDataApi<never>;
  readonly allowNegativeQuantity?: ToggleDataApi<never>;
  readonly automaticInventoryPush?: ToggleDataApi<never>;
  readonly allowModifyReceivingQuantity?: ToggleDataApi<ModifyReceivingDataApi>;
  readonly allowModifyReceivingPrice?: ToggleDataApi<ModifyReceivingDataApi>;
  readonly inventoryCosting?: InventoryCostingMethod;
  readonly preventBackdatedUpdates?: ToggleDataApi<never>;
  readonly preventOrderingOutOfStock?: ToggleDataApi<never>;
  readonly hideCKStock?: ToggleDataApi<never>;
  readonly allowCKInvoice?: ToggleDataApi<never>;
  readonly mandatoryAccountingCategoryOnItem?: ToggleDataApi<never>;
}

export interface UpdateRetailerSettingsRequest {
  readonly receivingAverageCostOptions?: ReceivingAverageCostOptions;
  readonly taxes?: TaxRate[];
  readonly displayStockCount?: boolean;
  readonly notificationEmail?: string;
  readonly alternateLanguage?: ToggleDataOptionalValue<Language>;
  readonly mandatoryAttachmentOnReceiving?: ToggleDataApi<never>;
}

export interface UpdateUserSettingsRequest {
  readonly sendNewOrderEmail?: boolean;
  readonly preferredLanguage?: Language;
}

export interface TaxRate {
  readonly id?: string;
  readonly name: string;
  readonly taxCode: string;
  readonly rate: number;
  readonly defaults: TaxRateDefaults;
  readonly isFixed?: boolean;
  readonly isExempt?: boolean;
}

export interface TaxRateDefaults {
  readonly defaultOnReceiving?: boolean;
  readonly defaultOnCredit?: boolean;
  readonly recipeCosting?: boolean;
}

export interface ReceivingAverageCostOptions {
  readonly discountAffectsAverageCost: ToggleDataApi<never>;
  readonly zeroPricedItemsAffectAverageCost: ToggleDataApi<never>;
  readonly higherPricedItemsAffectAverageCost: ToggleDataApi<never>;
}

export interface CreateWastageTypeRequest {
  readonly retailer: IdType;
  readonly name: string;
  readonly expense: boolean;
  readonly limit: number;
  readonly isActive: boolean;
}

export interface UpdateWastageTypeRequest extends CreateWastageTypeRequest {
  readonly id: string;
  readonly isSalesWastage: boolean;
}

export interface CreateRecipeCategoryRequest {
  readonly retailer: IdType;
  readonly parent?: IdType;
  readonly name: string;
  readonly isActive: boolean;
}

export interface UpdateRecipeCategoryRequest extends CreateRecipeCategoryRequest {
  readonly id: string;
}

export interface RemoveRecipeCategoryRequest {
  readonly id: string;
  readonly retailer: IdType;
}

export interface AccountingCategoryApi {
  readonly id: string;
  readonly name: string;
  readonly isActive: boolean;
}

export interface CreateAccountingCategoryRequest {
  readonly retailer: IdType;
  readonly name: string;
  readonly isActive: boolean;
}

export interface UpdateAccountingCategoryRequest extends CreateAccountingCategoryRequest {
  readonly id: string;
}

export interface SalesTypeBase {
  code: string;
  name: string;
  isDefault?: boolean;
  isNew?: boolean;
}

export interface SalesType extends SalesTypeBase {
  id: string;
}
export interface SalesTypeStateModel {
  salesTypes: SalesType[];
}
