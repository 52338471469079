"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DashboardActionEnum = void 0;
var DashboardActionEnum;
(function (DashboardActionEnum) {
  DashboardActionEnum["ViewProcurement"] = "view:procurement";
  DashboardActionEnum["ViewTheoreticalCost"] = "view:theoretical-cost";
  DashboardActionEnum["ViewActualCost"] = "view:actual-cost";
  DashboardActionEnum["ViewVariance"] = "view:variance";
  DashboardActionEnum["ViewWastage"] = "view:wastage";
  DashboardActionEnum["ViewSales"] = "view:sales";
  DashboardActionEnum["ViewTransfer"] = "view:transfer";
})(DashboardActionEnum = exports.DashboardActionEnum || (exports.DashboardActionEnum = {}));
