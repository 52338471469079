import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { UsersService } from '../../services';

@Injectable()
export class SyncUserResolver {
  constructor(private readonly usersService: UsersService) {}

  resolve(): Observable<boolean> {
    return this.usersService.syncUser().pipe(map(() => true));
  }
}
