"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TestResourceActionEnum = void 0;
var TestResourceActionEnum;
(function (TestResourceActionEnum) {
  TestResourceActionEnum["TestActionOne"] = "action:one";
  TestResourceActionEnum["TestActionTwo"] = "action:two";
  TestResourceActionEnum["TestActionThree"] = "action:three";
  TestResourceActionEnum["TestActionFour"] = "action:four";
  TestResourceActionEnum["TestActionFive"] = "action:three";
  TestResourceActionEnum["TestActionSix"] = "action:six";
  TestResourceActionEnum["TestActionSeven"] = "action:seven";
  TestResourceActionEnum["TestActionEight"] = "action:eight";
  TestResourceActionEnum["TestActionNine"] = "action:nine";
  TestResourceActionEnum["TestActionTen"] = "action:ten";
  TestResourceActionEnum["TestActionEleven"] = "action:eleven";
  TestResourceActionEnum["TestActionTwelve"] = "action:twelve";
})(TestResourceActionEnum = exports.TestResourceActionEnum || (exports.TestResourceActionEnum = {}));
