"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserActionEnum = void 0;
var UserActionEnum;
(function (UserActionEnum) {
  UserActionEnum["View"] = "view";
  UserActionEnum["ViewList"] = "view:list";
  UserActionEnum["Create"] = "create";
  UserActionEnum["UpdateDetails"] = "update:details";
  UserActionEnum["UpdateLocations"] = "update:locations";
  UserActionEnum["UpdateRole"] = "update:role";
  UserActionEnum["Delete"] = "delete";
})(UserActionEnum = exports.UserActionEnum || (exports.UserActionEnum = {}));
