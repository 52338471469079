"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CentralKitchenOrderActionEnum = void 0;
var CentralKitchenOrderActionEnum;
(function (CentralKitchenOrderActionEnum) {
  CentralKitchenOrderActionEnum["Ship"] = "ship";
  CentralKitchenOrderActionEnum["Confirm"] = "confirm";
  CentralKitchenOrderActionEnum["Reject"] = "reject";
  CentralKitchenOrderActionEnum["Create"] = "create";
})(CentralKitchenOrderActionEnum = exports.CentralKitchenOrderActionEnum || (exports.CentralKitchenOrderActionEnum = {}));
