import { map } from 'rxjs';
import { NgForOf } from '@angular/common';
import {
  Component,
  effect,
  EventEmitter,
  inject,
  Injector,
  Input,
  OnInit,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IDialogCancellableEventArgs, IDialogEventArgs } from '@infragistics/igniteui-angular';
import { Store } from '@ngxs/store';
import { actionsExecuting } from '@ngxs-labs/actions-executing';
import { Language } from '@supy.api/dictionaries';

import { Destroyable } from '@supy/common';
import {
  ButtonModule,
  CheckboxModule,
  DialogComponent,
  DialogModule,
  DialogService,
  IconModule,
  IDialogComponent,
  InputHintsModule,
  InputModule,
  PageService,
  SelectModule,
  SwitchModule,
  TagsModule,
} from '@supy/components';
import { UpdateUserSettingsRequest } from '@supy/settings';

import { CurrentUserState, GetCurrentUser, SaveCurrentUserSettings } from '../../store';

@Component({
  standalone: true,
  selector: 'supy-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
  providers: [DialogService],
  imports: [
    DialogModule,
    IconModule,
    ButtonModule,
    FormsModule,
    InputHintsModule,
    InputModule,
    NgForOf,
    ReactiveFormsModule,
    SelectModule,
    TagsModule,
    SwitchModule,
    CheckboxModule,
  ],
})
export class UserSettingsComponent extends Destroyable implements OnInit, IDialogComponent {
  readonly #store = inject(Store);
  readonly #injector = inject(Injector);
  readonly #pageService = inject(PageService);

  @ViewChild(DialogComponent, { static: true }) protected readonly dialog: DialogComponent;

  @Input() readonly overlayClass?: string;

  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();

  protected readonly preferredLanguage = this.#store.selectSignal(CurrentUserState.language);
  protected readonly sendNewOrderEmail = this.#store.selectSignal(CurrentUserState.sendNewOrderEmail);
  protected readonly languages = signal([
    { label: 'English', value: Language.English },
    { label: 'العربية', value: Language.Arabic },
    { label: 'Española', value: Language.Spanish },
  ]);

  protected readonly isLoading = toSignal(
    this.#store.select(actionsExecuting([SaveCurrentUserSettings, GetCurrentUser])).pipe(map(Boolean)),
  );

  protected readonly form = new FormGroup({
    sendNewOrderEmail: new FormControl<boolean>(false),
    preferredLanguage: new FormControl<Language | null>(null),
  });

  ngOnInit(): void {
    this.initializeFormState();
    this.initializeFormValue();

    this.#pageService.setHasNewNotifications(false);
  }

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.#pageService.hideUserSettingsDrawer();
    this.dialog.closeDialog();
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.#pageService.hideUserSettingsDrawer();
    this.dialogClosed.emit(event);
  }

  onSave(): void {
    const { sendNewOrderEmail, preferredLanguage } = this.form.value;
    const settings: UpdateUserSettingsRequest = {
      sendNewOrderEmail: sendNewOrderEmail as boolean,
      preferredLanguage: preferredLanguage as unknown as Language,
    };

    this.#store.dispatch(new SaveCurrentUserSettings(settings));
  }

  private initializeFormValue(): void {
    effect(
      () => {
        const preferredLanguage = this.preferredLanguage() ?? Language.English;
        const sendNewOrderEmail = this.sendNewOrderEmail();

        this.form.patchValue({
          preferredLanguage,
          sendNewOrderEmail,
        });
      },
      {
        injector: this.#injector,
      },
    );
  }

  private initializeFormState(): void {
    effect(
      () => {
        if (this.isLoading()) {
          this.form.disable();
        } else {
          this.form.enable();
        }
      },
      {
        injector: this.#injector,
      },
    );
  }
}
