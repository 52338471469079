"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CentralKitchenItemActionEnum = void 0;
var CentralKitchenItemActionEnum;
(function (CentralKitchenItemActionEnum) {
  CentralKitchenItemActionEnum["View"] = "view";
  CentralKitchenItemActionEnum["ViewList"] = "view:list";
  CentralKitchenItemActionEnum["Create"] = "create";
  CentralKitchenItemActionEnum["Update"] = "update";
  CentralKitchenItemActionEnum["Delete"] = "delete";
})(CentralKitchenItemActionEnum = exports.CentralKitchenItemActionEnum || (exports.CentralKitchenItemActionEnum = {}));
