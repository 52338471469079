"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RecipeActionEnum = void 0;
var RecipeActionEnum;
(function (RecipeActionEnum) {
  RecipeActionEnum["View"] = "view";
  /**
   * @deprecated
   */
  RecipeActionEnum["ViewList"] = "view:list";
  RecipeActionEnum["ViewHistory"] = "view:history";
  RecipeActionEnum["ViewCookbook"] = "view:cookbook";
  RecipeActionEnum["Create"] = "create";
  RecipeActionEnum["UpdateDetails"] = "update:details";
  RecipeActionEnum["UpdateCost"] = "update:cost";
  RecipeActionEnum["UpdateLocations"] = "update:locations";
  RecipeActionEnum["UpdateState"] = "update:state";
  RecipeActionEnum["UpdateBackdated"] = "update:backdated";
})(RecipeActionEnum = exports.RecipeActionEnum || (exports.RecipeActionEnum = {}));
