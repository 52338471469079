"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuditLogActionEnum = void 0;
var AuditLogActionEnum;
(function (AuditLogActionEnum) {
  AuditLogActionEnum["View"] = "view";
})(AuditLogActionEnum = exports.AuditLogActionEnum || (exports.AuditLogActionEnum = {}));
