"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LocationActionEnum = void 0;
var LocationActionEnum;
(function (LocationActionEnum) {
  LocationActionEnum["Create"] = "create";
  LocationActionEnum["Update"] = "update";
})(LocationActionEnum = exports.LocationActionEnum || (exports.LocationActionEnum = {}));
