"use strict";

var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DomainResource = exports.ResourceAction = void 0;
const actions_1 = require("./actions");
const domains_1 = require("./domains");
const resources_1 = require("./resources");
class ResourceAction {}
exports.ResourceAction = ResourceAction;
_a = resources_1.ResourceEnum.CreditNote, _b = resources_1.ResourceEnum.Grn, _c = resources_1.ResourceEnum.SupplierReturn, _d = resources_1.ResourceEnum.AuditLog, _e = resources_1.ResourceEnum.Branch, _f = resources_1.ResourceEnum.Location, _g = resources_1.ResourceEnum.Permission, _h = resources_1.ResourceEnum.PermissionPolicy, _j = resources_1.ResourceEnum.Retailer, _k = resources_1.ResourceEnum.Role, _l = resources_1.ResourceEnum.User, _m = resources_1.ResourceEnum.Dashboard, _o = resources_1.ResourceEnum.ProcurementReport, _p = resources_1.ResourceEnum.FinancialReport, _q = resources_1.ResourceEnum.InventoryReport, _r = resources_1.ResourceEnum.CentralKitchenOrder, _s = resources_1.ResourceEnum.CentralKitchenItem, _t = resources_1.ResourceEnum.CentralKitchenPriceList, _u = resources_1.ResourceEnum.Customer, _v = resources_1.ResourceEnum.CustomerGroup, _w = resources_1.ResourceEnum.AccountingIntegration, _x = resources_1.ResourceEnum.PosIntegration, _y = resources_1.ResourceEnum.InventoryItem, _z = resources_1.ResourceEnum.Production, _0 = resources_1.ResourceEnum.StockCount, _1 = resources_1.ResourceEnum.Transfer, _2 = resources_1.ResourceEnum.Wastage, _3 = resources_1.ResourceEnum.Recipe, _4 = resources_1.ResourceEnum.ChannelItem, _5 = resources_1.ResourceEnum.Packaging, _6 = resources_1.ResourceEnum.RetailerItem, _7 = resources_1.ResourceEnum.CategoryOrder, _8 = resources_1.ResourceEnum.Order, _9 = resources_1.ResourceEnum.Channel, _10 = resources_1.ResourceEnum.TestResource;
// Accounting
ResourceAction[_a] = actions_1.CreditNoteActionEnum;
ResourceAction[_b] = actions_1.GrnActionEnum;
ResourceAction[_c] = actions_1.SupplierReturnActionEnum;
// Administration
ResourceAction[_d] = actions_1.AuditLogActionEnum;
ResourceAction[_e] = actions_1.BranchActionEnum;
ResourceAction[_f] = actions_1.LocationActionEnum;
ResourceAction[_g] = actions_1.PermissionActionEnum;
ResourceAction[_h] = actions_1.PermissionPolicyActionEnum;
ResourceAction[_j] = actions_1.RetailerActionEnum;
ResourceAction[_k] = actions_1.RoleActionEnum;
ResourceAction[_l] = actions_1.UserActionEnum;
// Analytics
ResourceAction[_m] = actions_1.DashboardActionEnum;
ResourceAction[_o] = actions_1.ProcurementReportActionEnum;
ResourceAction[_p] = actions_1.FinancialReportActionEnum;
ResourceAction[_q] = actions_1.InventoryReportActionEnum;
// CentralKitchen
ResourceAction[_r] = actions_1.CentralKitchenOrderActionEnum;
ResourceAction[_s] = actions_1.CentralKitchenItemActionEnum;
ResourceAction[_t] = actions_1.CentralKitchenPriceListActionEnum;
ResourceAction[_u] = actions_1.CustomerActionEnum;
ResourceAction[_v] = actions_1.CustomerGroupActionEnum;
// Integration
ResourceAction[_w] = actions_1.AccountingIntegrationActionEnum;
ResourceAction[_x] = actions_1.PosIntegrationActionEnum;
// Inventory
ResourceAction[_y] = actions_1.InventoryItemActionEnum;
ResourceAction[_z] = actions_1.ProductionActionEnum;
ResourceAction[_0] = actions_1.StockCountActionEnum;
ResourceAction[_1] = actions_1.TransferActionEnum;
ResourceAction[_2] = actions_1.WastageActionEnum;
//Recipe
ResourceAction[_3] = actions_1.RecipeActionEnum;
// Procurement
ResourceAction[_4] = actions_1.ChannelItemActionEnum;
ResourceAction[_5] = actions_1.PackagingActionEnum;
ResourceAction[_6] = actions_1.RetailerItemActionEnum;
ResourceAction[_7] = actions_1.CategoryOrderActionEnum;
ResourceAction[_8] = actions_1.OrderActionEnum;
ResourceAction[_9] = actions_1.ChannelActionEnum;
// Test
ResourceAction[_10] = actions_1.TestResourceActionEnum;
class DomainResource {}
exports.DomainResource = DomainResource;
_11 = domains_1.DomainEnum.Accounting, _12 = domains_1.DomainEnum.Administration, _13 = domains_1.DomainEnum.Analytics, _14 = domains_1.DomainEnum.CentralKitchen, _15 = domains_1.DomainEnum.Integration, _16 = domains_1.DomainEnum.Inventory, _17 = domains_1.DomainEnum.Recipe, _18 = domains_1.DomainEnum.Procurement, _19 = domains_1.DomainEnum.TestDomain;
// Accounting
DomainResource[_11] = [resources_1.ResourceEnum.CreditNote, resources_1.ResourceEnum.Grn, resources_1.ResourceEnum.SupplierReturn];
// Administration
DomainResource[_12] = [resources_1.ResourceEnum.AuditLog, resources_1.ResourceEnum.Branch, resources_1.ResourceEnum.Location, resources_1.ResourceEnum.Permission, resources_1.ResourceEnum.PermissionPolicy, resources_1.ResourceEnum.Retailer, resources_1.ResourceEnum.Role, resources_1.ResourceEnum.User
/**
 * @deprecated
 */
// ResourceEnum.Supplier,
];
// Analytics
DomainResource[_13] = [resources_1.ResourceEnum.Dashboard, resources_1.ResourceEnum.FinancialReport, resources_1.ResourceEnum.InventoryReport, resources_1.ResourceEnum.ProcurementReport];
// CentralKitchen
DomainResource[_14] = [resources_1.ResourceEnum.CentralKitchenOrder, resources_1.ResourceEnum.CentralKitchenItem, resources_1.ResourceEnum.CentralKitchenPriceList, resources_1.ResourceEnum.Customer, resources_1.ResourceEnum.CustomerGroup];
// Integration
DomainResource[_15] = [resources_1.ResourceEnum.AccountingIntegration, resources_1.ResourceEnum.PosIntegration
/**
 * @deprecated
 */
// ResourceEnum.InventoryIntegration,
];
// Inventory
DomainResource[_16] = [resources_1.ResourceEnum.InventoryItem, resources_1.ResourceEnum.Production, resources_1.ResourceEnum.StockCount, resources_1.ResourceEnum.Transfer, resources_1.ResourceEnum.Wastage];
// Recipe
DomainResource[_17] = [resources_1.ResourceEnum.Recipe];
// Procurement
DomainResource[_18] = [resources_1.ResourceEnum.CategoryOrder, resources_1.ResourceEnum.Channel, resources_1.ResourceEnum.ChannelItem, resources_1.ResourceEnum.Order, resources_1.ResourceEnum.Packaging, resources_1.ResourceEnum.RetailerItem];
//Test
DomainResource[_19] = [resources_1.ResourceEnum.TestResource];
