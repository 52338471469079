"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProductionActionEnum = void 0;
var ProductionActionEnum;
(function (ProductionActionEnum) {
  ProductionActionEnum["View"] = "view";
  ProductionActionEnum["Create"] = "create";
  ProductionActionEnum["Submit"] = "submit";
  ProductionActionEnum["Delete"] = "delete";
  /**
   * @deprecated
   */
  // Draft = 'draft',
  // Update = 'update',
})(ProductionActionEnum = exports.ProductionActionEnum || (exports.ProductionActionEnum = {}));
