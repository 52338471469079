"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CustomerGroupActionEnum = void 0;
var CustomerGroupActionEnum;
(function (CustomerGroupActionEnum) {
  CustomerGroupActionEnum["View"] = "view";
  CustomerGroupActionEnum["ViewList"] = "view:list";
  CustomerGroupActionEnum["Create"] = "create";
  CustomerGroupActionEnum["Update"] = "update";
  CustomerGroupActionEnum["Delete"] = "delete";
})(CustomerGroupActionEnum = exports.CustomerGroupActionEnum || (exports.CustomerGroupActionEnum = {}));
