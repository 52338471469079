import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { User } from '@supy/common';

import { UsersService } from '../../services';

@Injectable()
export class UserResolver {
  constructor(private readonly usersService: UsersService) {}

  resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<User> {
    return this.usersService.getUser(route.params.id as string);
  }
}
