import { catchError, EMPTY, of, tap } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { computed, effect, inject, Injectable, Injector } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Action, createSelector, NgxsOnInit, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { getCurrentScope } from '@sentry/browser';
import { Language } from '@supy.api/dictionaries';

import { LoginSuccess, Logout } from '@supy/authentication';
import {
  QueryBuilder,
  QueryPaging,
  RESTRICTED_SCOPES,
  RoleScopeType,
  ToggleFeature,
  UnleashService,
  User,
  UserSettings,
  UserState,
  UserWithBranches,
} from '@supy/common';
import { getLocalizedName, UserSettingsService } from '@supy/settings';

import { UsersService } from '../../services/users';
import {
  GetCurrentUser,
  GetCurrentUserError,
  GetCurrentUserSuccess,
  GetRetailerUser,
  GetRetailerUsers,
  RemoveUsersFromRetailer,
  ResetRetailerUser,
  SaveCurrentUserSettings,
  SetFavouriteReports,
} from '../actions';

export interface CurrentUserStateModel {
  readonly user: UserWithBranches | null;
  readonly loadedFromApi: boolean;
  readonly retailerUsers: UserWithBranches[];
  readonly selectedRetailerUser: UserWithBranches | null;
  readonly favouriteReports: string[];
}

export const CURRENT_USER_STATE_TOKEN = new StateToken<CurrentUserStateModel>('currentUser');

const ALLOWED_LANGUAGES_LIST = [Language.English, Language.Arabic, Language.Spanish];

@State<CurrentUserStateModel>({
  name: CURRENT_USER_STATE_TOKEN,
})
@Injectable()
export class CurrentUserState implements NgxsOnInit {
  readonly #userSettingsService: UserSettingsService = inject(UserSettingsService);
  readonly #usersService: UsersService = inject(UsersService);
  readonly #document: Document = inject(DOCUMENT);
  readonly #injector: Injector = inject(Injector);
  readonly #store: Store = inject(Store);
  readonly #enabledToggles = toSignal(inject(UnleashService).enabledTogglesChanges$, { initialValue: [] });
  readonly canToggleUiLanguage = computed(() =>
    (this.#enabledToggles() as ToggleFeature[]).includes(ToggleFeature.Localize),
  );

  constructor() {
    this.listenForUnleashToggles();
  }

  @Selector()
  static isSuperadmin(state: CurrentUserStateModel): boolean {
    return isSuperadmin(state.user);
  }

  @Selector()
  static getCurrentUser(state: CurrentUserStateModel): UserWithBranches | null {
    return state.user;
  }

  @Selector()
  static getCurrentUserSettings({ user }: CurrentUserStateModel): UserSettings | undefined {
    return user?.settings;
  }

  @Selector()
  static language(ctx: CurrentUserStateModel) {
    return this.getCurrentUserSettings(ctx)?.preferredLanguage || null;
  }

  @Selector()
  static sendNewOrderEmail(ctx: CurrentUserStateModel) {
    return this.getCurrentUserSettings(ctx)?.sendNewOrderEmail ?? false;
  }

  @Selector()
  static getFavouriteReports(state: CurrentUserStateModel): string[] {
    return state.favouriteReports ?? [];
  }

  @Selector()
  static userRelatedBranches(state: CurrentUserStateModel) {
    return (
      CurrentUserState.retailers(state)?.flatMap(retailer =>
        retailer.outlets.flatMap(outlet =>
          outlet.branches.map(branch => ({
            ...branch,
            name: `${getLocalizedName(outlet.name)} - ${branch.name}`,
            retailer,
            retailerId: retailer.id,
          })),
        ),
      ) ?? []
    );
  }

  @Selector()
  static userRelatedBranchIds(state: CurrentUserStateModel) {
    return state.user?.branchIds;
  }

  static retailerBranches(retailerId: string) {
    return createSelector([CurrentUserState], (state: CurrentUserStateModel) => {
      return state.user?.retailers
        ?.find(retailer => retailer.id === retailerId)
        ?.outlets?.flatMap(outlet =>
          outlet.branches.map(branch => ({ ...branch, name: `${getLocalizedName(outlet.name)} - ${branch.name}` })),
        );
    });
  }

  static retailerOutletsByRetailerId(retailerId: string) {
    return createSelector([CurrentUserState], (state: CurrentUserStateModel) => {
      return state.user?.retailers?.find(retailer => retailer.id === retailerId)?.outlets || [];
    });
  }

  static retailerOutlet(outletId: string) {
    return createSelector([CurrentUserState], (state: CurrentUserStateModel) => {
      return state.user?.retailers.flatMap(retailer => retailer.outlets).find(({ id }) => outletId === id);
    });
  }

  @Selector()
  static getRetailer(state: CurrentUserStateModel) {
    const retailers = new Map(state.user?.retailers?.map(retailer => [retailer.id, retailer]));

    return (id: string) => retailers.get(id);
  }

  @Selector()
  static getBranch(state: CurrentUserStateModel) {
    const branches = new Map(CurrentUserState.userRelatedBranches(state)?.map(branch => [branch.id, branch]));

    return (id: string) => branches.get(id);
  }

  static branchLocation(locationId: string) {
    return createSelector([CurrentUserState], (state: CurrentUserStateModel) => {
      return CurrentUserState.userRelatedBranches(state).find(branch => branch.id === locationId);
    });
  }

  @Selector()
  static retailers(state: CurrentUserStateModel) {
    return state.user?.retailers?.length ? state.user.retailers : null;
  }

  @Selector()
  static outlets(state: CurrentUserStateModel) {
    return (
      CurrentUserState.retailers(state)?.flatMap(retailer =>
        retailer.outlets.map(outlet => ({ ...outlet, nameEN: outlet.name.en })),
      ) ?? []
    );
  }

  @Selector()
  static branches(state: CurrentUserStateModel) {
    return CurrentUserState.userRelatedBranches(state);
  }

  /** @deprecated */
  @Selector()
  static branchesWithoutOutletName(state: CurrentUserStateModel) {
    return (
      CurrentUserState.retailers(state)?.flatMap(retailer =>
        retailer.outlets.flatMap(outlet =>
          outlet.branches.map(branch => ({
            ...branch,
            retailer,
            retailerId: retailer.id,
          })),
        ),
      ) ?? []
    );
  }

  @Selector()
  static locationsAsTree(state: CurrentUserStateModel) {
    return CurrentUserState.outlets(state)?.map(outlet => ({
      id: outlet.id,
      name: getLocalizedName(outlet.name),
      children: outlet.branches?.map(branch => ({ id: branch.id, name: branch.name })),
      unselectable: true,
    }));
  }

  static locationWithParent(locationId: string) {
    return createSelector([CurrentUserState], (state: CurrentUserStateModel) => {
      for (const branch of CurrentUserState.locationsAsTree(state)) {
        if (!branch.children?.length) {
          continue;
        }

        for (const location of branch.children) {
          if (location.id === locationId) {
            return { id: location.id, name: location.name, parent: { id: branch.id, name: branch.name } };
          }
        }
      }

      return null;
    });
  }

  @Selector()
  static storages(state: CurrentUserStateModel) {
    return CurrentUserState.userRelatedBranches(state)?.map(branch => ({
      id: branch.id,
      name: branch.name,
      children: branch.storageLocations,
    }));
  }

  @Selector()
  static storagesAsTree(state: CurrentUserStateModel) {
    return CurrentUserState.userRelatedBranches(state)?.map(branch => ({
      id: branch.id,
      name: branch.name,
      children: branch.storageLocations?.map(sl => ({ id: sl.id, name: getLocalizedName(sl.name) })),
    }));
  }

  @Selector()
  static roleIdsCSV(state: CurrentUserStateModel) {
    return state.user?.roles.map(({ id }) => id).join(',') ?? '';
  }

  @Selector()
  static retailerUsers(state: CurrentUserStateModel) {
    return state.retailerUsers ?? [];
  }

  @Selector()
  static selectedRetailerUser(state: CurrentUserStateModel) {
    return state.selectedRetailerUser;
  }

  ngxsOnInit(ctx: StateContext<CurrentUserStateModel>): void {
    ctx.dispatch(new GetCurrentUser());
  }

  @Action(GetCurrentUser, { cancelUncompleted: true })
  getCurrentUser(ctx: StateContext<CurrentUserStateModel>, { forceFetch }: GetCurrentUser) {
    const user = ctx.getState().user;
    /* should refresh user if loaded from cache */
    const loadedFromApi = ctx.getState().loadedFromApi;

    return user && !forceFetch && loadedFromApi
      ? of(user)
      : this.#usersService.getCurrentUserWithBranches().pipe(
          tap(userWithBranches => {
            ctx.dispatch(
              new GetCurrentUserSuccess({
                ...userWithBranches,
                retailers: isSuperadmin(userWithBranches) ? user?.retailers || [] : userWithBranches.retailers,
                retailerIds: isSuperadmin(userWithBranches) ? user?.retailerIds || [] : userWithBranches.retailerIds,
              }),
            );
          }),
          catchError((error: Error) => {
            ctx.dispatch(new GetCurrentUserError(error));

            return EMPTY;
          }),
        );
  }

  @Action(GetCurrentUserSuccess)
  getProfileSuccess(ctx: StateContext<CurrentUserStateModel>, { user }: GetCurrentUserSuccess): void {
    const scope = getCurrentScope();

    scope.setUser({ ...user });
    ctx.patchState({ user, loadedFromApi: true });

    if (this.canToggleUiLanguage()) {
      this.updateUserLanguage();
    }
  }

  @Action(LoginSuccess)
  loginSuccess(ctx: StateContext<CurrentUserStateModel>): void {
    ctx.dispatch(new GetCurrentUser());
  }

  @Action(Logout)
  logout(ctx: StateContext<CurrentUserStateModel>): void {
    ctx.patchState({ user: null });
  }

  @Action(RemoveUsersFromRetailer)
  removeUsersFromRetailer(_: StateContext<CurrentUserStateModel>, { body }: RemoveUsersFromRetailer) {
    return this.#usersService.removeUsersFromRetailerBFF(body);
  }

  @Action(GetRetailerUsers)
  getRetailerUsers(ctx: StateContext<CurrentUserStateModel>, { retailerId, term }: GetRetailerUsers) {
    const qb = new QueryBuilder<User>({
      paging: QueryPaging.NoLimit,
      filtering: [{ by: 'state', op: 'eq', match: UserState.active }],
      ordering: [
        { by: 'firstName', dir: 'asc' },
        { by: 'lastName', dir: 'asc' },
        { by: 'id', dir: 'desc' },
      ],
    });

    if (term) {
      qb.filtering.withGroup('or', [
        { by: 'firstName', op: 'like', match: term },
        { by: 'lastName', op: 'like', match: term },
        { by: 'email', op: 'like', match: term },
        { by: 'phone', op: 'like', match: term },
      ]);
    }

    return this.#usersService.getRetailerUsers(retailerId, qb.build()).pipe(
      tap(({ data }) =>
        ctx.patchState({
          retailerUsers: (data as UserWithBranches[]).filter(
            ({ roles }) => !RESTRICTED_SCOPES.has(roles[0]?.scope.type),
          ),
        }),
      ),
    );
  }

  @Action(GetRetailerUser)
  getRetailerUser(ctx: StateContext<CurrentUserStateModel>, { userId }: GetRetailerUser) {
    return this.#usersService.getRetailerUser(userId).pipe(tap(user => ctx.patchState({ selectedRetailerUser: user })));
  }

  @Action(ResetRetailerUser)
  resetRetailerUser(ctx: StateContext<CurrentUserStateModel>) {
    ctx.patchState({ selectedRetailerUser: null });
  }

  @Action(SetFavouriteReports)
  setFavouriteReports(ctx: StateContext<CurrentUserStateModel>, { reports }: SetFavouriteReports): void {
    ctx.patchState({ favouriteReports: reports });
  }

  @Action(SaveCurrentUserSettings)
  saveSettings({ dispatch }: StateContext<CurrentUserStateModel>, { payload }: SaveCurrentUserSettings) {
    return this.#userSettingsService.update(payload).pipe(tap(() => dispatch(new GetCurrentUser(true))));
  }

  private listenForUnleashToggles(): void {
    effect(
      () => {
        if (this.canToggleUiLanguage()) {
          this.updateUserLanguage();
        }
      },
      { injector: this.#injector },
    );
  }

  private updateUserLanguage(): void {
    const language = this.#store.selectSnapshot(CurrentUserState.language) ?? Language.English;

    const htmlTag = this.#document.querySelector('html');

    if (htmlTag) {
      const pageLang = htmlTag.lang as Language;

      htmlTag.dataset.userLanguage = language;

      if (pageLang !== language && ALLOWED_LANGUAGES_LIST.includes(language)) {
        localStorage.setItem('supy.locale', language);
        window.location.reload();
      }
    }
  }
}

export function isSuperadmin(user: UserWithBranches | null) {
  return user?.roles?.findIndex(({ scope }) => scope.type === RoleScopeType.Superadmin) !== -1;
}
