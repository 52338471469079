"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SupplierActionEnum = void 0;
var SupplierActionEnum;
(function (SupplierActionEnum) {})(SupplierActionEnum = exports.SupplierActionEnum || (exports.SupplierActionEnum = {}));
/**
 * @deprecated
 */
// ViewList = 'view:list',
// View = 'view',
// Create = 'create',
// Update = 'update',
// Delete = 'delete',
// Draft = 'draft',
// Approve = 'approve',
