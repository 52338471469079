import { Store } from '@ngxs/store';

import { Principal, Resource } from './cerbos-typings';

export const AUTHZ_DEFAULT_ID = 'new';

export interface GetIsAllowedRequestOptions {
  readonly store: Store;
  readonly kind: string;
  readonly action: string;
  readonly principal?: Principal;
  readonly resource?: Resource;
}
