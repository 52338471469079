"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InventoryIntegrationActionEnum = void 0;
var InventoryIntegrationActionEnum;
(function (InventoryIntegrationActionEnum) {})(InventoryIntegrationActionEnum = exports.InventoryIntegrationActionEnum || (exports.InventoryIntegrationActionEnum = {}));
/**
 * @deprecated
 */
// PushOrder = 'push:order',
//  UpdateItemMapping = 'update:mapping:item',
