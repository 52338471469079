"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OrderActionEnum = void 0;
var OrderActionEnum;
(function (OrderActionEnum) {
  OrderActionEnum["Draft"] = "draft";
  OrderActionEnum["Discard"] = "discard";
  OrderActionEnum["Submit"] = "submit";
  OrderActionEnum["SubmitAbovePar"] = "submit:above-par";
  OrderActionEnum["Update"] = "update";
  OrderActionEnum["View"] = "view";
  OrderActionEnum["ViewList"] = "view:list";
  OrderActionEnum["Close"] = "close";
  OrderActionEnum["Receive"] = "receive";
})(OrderActionEnum = exports.OrderActionEnum || (exports.OrderActionEnum = {}));
