import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { USER_SETTINGS_BFF_URI } from '../config';
import { UpdateUserSettingsRequest, UserSettings } from '../core';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(USER_SETTINGS_BFF_URI) private readonly bffUri: string,
  ) {}

  get(): Observable<UserSettings> {
    return this.httpClient.get<UserSettings>(`${this.bffUri}`);
  }

  update(body: Partial<UpdateUserSettingsRequest>): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}`, body);
  }
}
