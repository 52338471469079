"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChannelActionEnum = void 0;
var ChannelActionEnum;
(function (ChannelActionEnum) {
  ChannelActionEnum["View"] = "view";
  ChannelActionEnum["Link"] = "link";
  ChannelActionEnum["Update"] = "update";
  /**
   * @deprecated
   */
  // ViewList = 'view:list',
})(ChannelActionEnum = exports.ChannelActionEnum || (exports.ChannelActionEnum = {}));
