"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ResourceEnum = void 0;
var ResourceEnum;
(function (ResourceEnum) {
  // Accounting
  ResourceEnum["CreditNote"] = "CreditNote";
  ResourceEnum["Grn"] = "Grn";
  ResourceEnum["SupplierReturn"] = "SupplierReturn";
  // Administration
  ResourceEnum["AuditLog"] = "AuditLog";
  ResourceEnum["Branch"] = "Branch";
  ResourceEnum["Location"] = "Location";
  ResourceEnum["Permission"] = "Permission";
  ResourceEnum["PermissionPolicy"] = "PermissionPolicy";
  ResourceEnum["Retailer"] = "Retailer";
  ResourceEnum["Role"] = "Role";
  ResourceEnum["User"] = "User";
  // Analytics
  ResourceEnum["Dashboard"] = "Dashboard";
  ResourceEnum["ProcurementReport"] = "ProcurementReport";
  ResourceEnum["FinancialReport"] = "FinancialReport";
  ResourceEnum["InventoryReport"] = "InventoryReport";
  // CentralKitchen
  ResourceEnum["CentralKitchenOrder"] = "CentralKitchenOrder";
  ResourceEnum["Customer"] = "Customer";
  ResourceEnum["CentralKitchenItem"] = "CentralKitchenItem";
  ResourceEnum["CustomerGroup"] = "CustomerGroup";
  ResourceEnum["CentralKitchenPriceList"] = "CentralKitchenPriceList";
  // Integration
  ResourceEnum["AccountingIntegration"] = "AccountingIntegration";
  ResourceEnum["PosIntegration"] = "PosIntegration";
  // Inventory
  ResourceEnum["InventoryItem"] = "InventoryItem";
  ResourceEnum["Production"] = "Production";
  ResourceEnum["StockCount"] = "StockCount";
  ResourceEnum["Transfer"] = "Transfer";
  ResourceEnum["Wastage"] = "Wastage";
  //Recipe
  ResourceEnum["Recipe"] = "Recipe";
  // Procurement
  ResourceEnum["Channel"] = "Channel";
  ResourceEnum["CategoryOrder"] = "CategoryOrder";
  ResourceEnum["Order"] = "Order";
  ResourceEnum["ChannelItem"] = "ChannelItem";
  ResourceEnum["Packaging"] = "Packaging";
  ResourceEnum["RetailerItem"] = "RetailerItem";
  //Test
  ResourceEnum["TestResource"] = "TestResource";
  /**
   * @deprecated
   */
  // Supplier = 'Supplier',
  // InventoryIntegration = 'InventoryIntegration',
})(ResourceEnum = exports.ResourceEnum || (exports.ResourceEnum = {}));
