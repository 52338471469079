"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StockCountActionEnum = void 0;
var StockCountActionEnum;
(function (StockCountActionEnum) {
  StockCountActionEnum["View"] = "view";
  StockCountActionEnum["Create"] = "create";
  StockCountActionEnum["CreateSubCount"] = "create:sub-count";
  StockCountActionEnum["Delete"] = "delete";
  StockCountActionEnum["DeleteSubCount"] = "delete:sub-count";
  StockCountActionEnum["Submit"] = "submit";
  StockCountActionEnum["Update"] = "update";
  StockCountActionEnum["ViewVarianceReport"] = "view:variance-report";
  StockCountActionEnum["UpdateZero"] = "update:zero";
  StockCountActionEnum["ViewList"] = "view:list";
  StockCountActionEnum["Reopen"] = "reopen";
})(StockCountActionEnum = exports.StockCountActionEnum || (exports.StockCountActionEnum = {}));
