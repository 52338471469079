"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SupplierReturnActionEnum = void 0;
var SupplierReturnActionEnum;
(function (SupplierReturnActionEnum) {
  SupplierReturnActionEnum["ViewList"] = "view:list";
  SupplierReturnActionEnum["View"] = "view";
  SupplierReturnActionEnum["Create"] = "create";
  SupplierReturnActionEnum["UpdateQuantities"] = "update:quantities";
  SupplierReturnActionEnum["UpdateInvoicePrices"] = "update:invoice-prices";
  SupplierReturnActionEnum["UpdateLocked"] = "update:locked";
  SupplierReturnActionEnum["Lock"] = "lock";
  SupplierReturnActionEnum["Post"] = "post";
  SupplierReturnActionEnum["Approve"] = "approve";
  /**
   * @deprecated
   */
  //  Unlock = 'unlock',
  //  UpdateStock = 'update:stock',
})(SupplierReturnActionEnum = exports.SupplierReturnActionEnum || (exports.SupplierReturnActionEnum = {}));
