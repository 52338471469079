import { UserWithBranches } from '@supy/common';
import { UpdateUserSettingsRequest } from '@supy/settings';

import { RemoveUsersFromRetailerRequest } from '../../core';

export class GetCurrentUser {
  static type = '[CurrentUser] GetCurrentUser';

  constructor(readonly forceFetch = false) {}
}

export class GetCurrentUserSuccess {
  static type = '[CurrentUser] GetCurrentUserSuccess';

  constructor(readonly user: UserWithBranches) {}
}

export class GetCurrentUserError {
  static type = '[CurrentUser] GetCurrentUserError';

  constructor(readonly error: Error) {}
}

export class SetFavouriteReports {
  static type = '[CurrentUser] SetFavouriteReports';

  constructor(readonly reports: string[]) {}
}

export class RemoveUsersFromRetailer {
  static type = '[CurrentUser] RemoveUsersFromRetailer';

  constructor(readonly body: RemoveUsersFromRetailerRequest) {}
}

export class GetRetailerUsers {
  static type = '[CurrentUser] GetRetailerUsers';

  constructor(
    readonly retailerId: string,
    readonly term?: string,
  ) {}
}

export class GetRetailerUser {
  static type = '[CurrentUser] GetRetailerUser';

  constructor(readonly userId: string) {}
}

export class ResetRetailerUser {
  static type = '[CurrentUser] ResetRetailerUser';
}

export class SaveCurrentUserSettings {
  static readonly type = '[CurrentUser] Save Settings';
  constructor(readonly payload: Partial<UpdateUserSettingsRequest>) {}
}
