"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CentralKitchenPriceListActionEnum = void 0;
var CentralKitchenPriceListActionEnum;
(function (CentralKitchenPriceListActionEnum) {
  CentralKitchenPriceListActionEnum["View"] = "view";
  CentralKitchenPriceListActionEnum["ViewList"] = "view:list";
  CentralKitchenPriceListActionEnum["Create"] = "create";
  CentralKitchenPriceListActionEnum["Update"] = "update";
  CentralKitchenPriceListActionEnum["Delete"] = "delete";
})(CentralKitchenPriceListActionEnum = exports.CentralKitchenPriceListActionEnum || (exports.CentralKitchenPriceListActionEnum = {}));
