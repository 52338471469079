import { first, from, Observable, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { BaseUnleashResolver, UnleashService } from '@supy/common';

import { CurrentUserState } from '../../store';

/**
 * @description
 * Used as a resolver that extracts user id from storage and uses it as context
 *
 * @usage
 * ```Typescript
 * const routes: UnleashRoute[] = [
 *  {
 *    path: '',
 *    resolve: {unleashUser: CurrentUserUnleashResolver}
 *  }
 * ];
 *
 * ```
 */
@Injectable({ providedIn: 'root' })
export class CurrentUserUnleashResolver extends BaseUnleashResolver {
  constructor(
    protected readonly unleashService: UnleashService,
    private readonly store: Store,
  ) {
    super();
  }

  resolve(): Observable<void> {
    return this.store.select(CurrentUserState.getCurrentUser).pipe(
      first(Boolean),
      switchMap(user => from(this.updateContextAndResolve({ userId: user.id }))),
    );
  }
}
