"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BranchActionEnum = void 0;
var BranchActionEnum;
(function (BranchActionEnum) {
  BranchActionEnum["Create"] = "create";
  BranchActionEnum["Update"] = "update";
  BranchActionEnum["UpdateMetadata"] = "update:metadata";
})(BranchActionEnum = exports.BranchActionEnum || (exports.BranchActionEnum = {}));
