import { CreateBranchRegionRequest } from '@supy/common';

import { UpdateRetailerSettingsRequest } from '../../core';

export class RetailerSettingsGet {
  static readonly type = '[RetailerSettings] Get';
  constructor(readonly payload: { retailerId: string }) {}
}

export class RetailerSettingsUpdate {
  static readonly type = '[RetailerSettings] Update';
  constructor(readonly payload: UpdateRetailerSettingsRequest) {}
}

export class RetailerSettingsCreateBranchRegion {
  static readonly type = '[RetailerSettings] CreateBranchRegion';
  constructor(readonly payload: CreateBranchRegionRequest) {}
}

export class RetailerSettingsDeleteBranchRegion {
  static readonly type = '[RetailerSettings] DeleteBranchRegion';
  constructor(readonly payload: { regionId: string }) {}
}
