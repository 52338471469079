"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PermissionActionEnum = void 0;
var PermissionActionEnum;
(function (PermissionActionEnum) {
  PermissionActionEnum["Create"] = "create";
  PermissionActionEnum["Update"] = "update";
  PermissionActionEnum["View"] = "view";
  PermissionActionEnum["Delete"] = "delete";
  PermissionActionEnum["ViewList"] = "view:list";
})(PermissionActionEnum = exports.PermissionActionEnum || (exports.PermissionActionEnum = {}));
