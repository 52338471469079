import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';

import { ButtonModule, ImageUploaderModule, InputModule, SelectModule, SwitchModule } from '@supy/components';

import { UserFormComponent } from './components';
import { CurrentUserUnleashResolver, SyncUserResolver, UserResolver } from './resolvers';
import { UsersService } from './services';
import { CurrentUserState } from './store';

@NgModule({
  providers: [UsersService, UserResolver, CurrentUserUnleashResolver, SyncUserResolver],
  imports: [
    CommonModule,
    ImageUploaderModule,
    ButtonModule,
    ImageUploaderModule,
    InputModule,
    SelectModule,
    ReactiveFormsModule,
    NgxsModule.forFeature([CurrentUserState]),
    SwitchModule,
  ],
  declarations: [UserFormComponent],
  exports: [UserFormComponent],
})
export class UsersModule {}
