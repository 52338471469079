"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RoleActionEnum = void 0;
var RoleActionEnum;
(function (RoleActionEnum) {
  RoleActionEnum["Create"] = "create";
  RoleActionEnum["Update"] = "update";
  RoleActionEnum["View"] = "view";
  RoleActionEnum["Disable"] = "disable";
})(RoleActionEnum = exports.RoleActionEnum || (exports.RoleActionEnum = {}));
