"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CustomerActionEnum = void 0;
var CustomerActionEnum;
(function (CustomerActionEnum) {
  CustomerActionEnum["View"] = "view";
  CustomerActionEnum["ViewList"] = "view:list";
  CustomerActionEnum["Create"] = "create";
  CustomerActionEnum["Update"] = "update";
  CustomerActionEnum["Delete"] = "delete";
})(CustomerActionEnum = exports.CustomerActionEnum || (exports.CustomerActionEnum = {}));
