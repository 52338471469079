"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PackagingActionEnum = void 0;
var PackagingActionEnum;
(function (PackagingActionEnum) {
  PackagingActionEnum["View"] = "view";
  PackagingActionEnum["Create"] = "create";
  PackagingActionEnum["Update"] = "update";
  PackagingActionEnum["Delete"] = "delete";
  PackagingActionEnum["UpdateCounting"] = "update:counting";
})(PackagingActionEnum = exports.PackagingActionEnum || (exports.PackagingActionEnum = {}));
