"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChannelItemActionEnum = void 0;
var ChannelItemActionEnum;
(function (ChannelItemActionEnum) {
  ChannelItemActionEnum["View"] = "view";
  ChannelItemActionEnum["Create"] = "create";
  ChannelItemActionEnum["Update"] = "update";
  ChannelItemActionEnum["UpdatePriority"] = "update:priority";
  ChannelItemActionEnum["Delete"] = "delete";
  ChannelItemActionEnum["Disable"] = "disable";
})(ChannelItemActionEnum = exports.ChannelItemActionEnum || (exports.ChannelItemActionEnum = {}));
