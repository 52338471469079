import { map, switchMap, tap } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';

import { RetailerSettings } from '../../core';
import { RetailerSettingsService } from '../../services';
import {
  RetailerSettingsCreateBranchRegion,
  RetailerSettingsDeleteBranchRegion,
  RetailerSettingsGet,
  RetailerSettingsUpdate,
} from '../actions';

export interface RetailerSettingStateModel extends RetailerSettings {
  readonly retailerId: string;
}

export const RETAILER_SETTINGS_STATE_TOKEN = new StateToken<RetailerSettingStateModel>('retailerSettings');

@State<RetailerSettingStateModel>({
  name: RETAILER_SETTINGS_STATE_TOKEN,
})
@Injectable()
export class RetailerSettingsState {
  @Selector()
  static id({ id }: RetailerSettingStateModel) {
    return id;
  }

  @Selector()
  static taxes({ taxes }: RetailerSettingStateModel) {
    return taxes ?? [];
  }

  @Selector()
  static salesTypes({ salesTypes }: RetailerSettingStateModel) {
    return salesTypes ?? [];
  }

  @Selector()
  static alternateLanguage({ alternateLanguage }: RetailerSettingStateModel) {
    return alternateLanguage;
  }

  @Selector()
  static branchRegions({ branchRegions = [] }: RetailerSettingStateModel) {
    return branchRegions;
  }

  @Selector()
  static defaultOnReceivingTax({ taxes }: RetailerSettingStateModel) {
    return taxes?.find(({ defaults }) => defaults.defaultOnReceiving);
  }

  @Selector()
  static exemptTax({ taxes }: RetailerSettingStateModel) {
    return taxes?.find(({ isExempt }) => !!isExempt);
  }

  @Selector()
  static mandatoryAttachmentOnReceiving({ mandatoryAttachmentOnReceiving }: RetailerSettingStateModel) {
    return mandatoryAttachmentOnReceiving?.enabled;
  }

  @Selector()
  static receivingAverageCostOptions({ receivingAverageCostOptions }: RetailerSettingStateModel) {
    return receivingAverageCostOptions;
  }

  @Selector()
  static recipeCostingTaxes({ taxes }: RetailerSettingStateModel) {
    return taxes?.filter(tax => tax.defaults.recipeCosting) ?? [];
  }

  @Selector()
  static currency({ country }: RetailerSettingStateModel) {
    return country?.currency ?? '';
  }

  readonly #document: Document = inject(DOCUMENT);
  readonly #retailerSettingsService: RetailerSettingsService = inject(RetailerSettingsService);

  @Action(RetailerSettingsGet)
  getRetailerSettings(
    { setState }: StateContext<RetailerSettingStateModel>,
    { payload: { retailerId } }: RetailerSettingsGet,
  ) {
    return this.#retailerSettingsService.get(retailerId).pipe(
      tap(data => {
        setState({ ...data, retailerId });

        const htmlTag = this.#document.querySelector('html');

        if (htmlTag && data.alternateLanguage?.enabled) {
          htmlTag.dataset.alternateLanguage = data.alternateLanguage.value;
        }
      }),
    );
  }

  @Action(RetailerSettingsUpdate)
  updateRetailerSettings(
    { getState, dispatch }: StateContext<RetailerSettingStateModel>,
    { payload }: RetailerSettingsUpdate,
  ) {
    return this.#retailerSettingsService
      .update(getState().retailerId, payload)
      .pipe(tap(() => dispatch(new RetailerSettingsGet({ retailerId: getState().retailerId }))));
  }

  @Action(RetailerSettingsCreateBranchRegion)
  createBranchRegion(
    { getState, dispatch }: StateContext<RetailerSettingStateModel>,
    { payload }: RetailerSettingsCreateBranchRegion,
  ) {
    return this.#retailerSettingsService
      .createBranchRegion(payload)
      .pipe(
        switchMap(region =>
          dispatch(new RetailerSettingsGet({ retailerId: getState().retailerId })).pipe(map(() => region)),
        ),
      );
  }

  @Action(RetailerSettingsDeleteBranchRegion)
  deleteBranchRegion(
    { getState, dispatch }: StateContext<RetailerSettingStateModel>,
    { payload: { regionId } }: RetailerSettingsDeleteBranchRegion,
  ) {
    return this.#retailerSettingsService
      .deleteBranchRegion(regionId)
      .pipe(switchMap(() => dispatch(new RetailerSettingsGet({ retailerId: getState().retailerId }))));
  }
}
