"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TransferActionEnum = void 0;
var TransferActionEnum;
(function (TransferActionEnum) {
  TransferActionEnum["View"] = "view";
  TransferActionEnum["Create"] = "create";
  TransferActionEnum["Receive"] = "receive";
  TransferActionEnum["Submit"] = "submit";
  TransferActionEnum["Update"] = "update";
  TransferActionEnum["Archive"] = "archive";
  TransferActionEnum["Request"] = "request";
  /**
   * @deprecated
   */
  // ViewList = 'view:list',
})(TransferActionEnum = exports.TransferActionEnum || (exports.TransferActionEnum = {}));
