import {
  AccountingCategory,
  CreateAccountingCategoryRequest,
  CreateRecipeCategoryRequest,
  CreateWastageTypeRequest,
  RemoveRecipeCategoryRequest,
  SalesType,
  SalesTypeBase,
  Settings,
  UpdateAccountingCategoryRequest,
  UpdateRecipeCategoryRequest,
  UpdateWastageTypeRequest,
  WastageType,
} from '../../core';

export class GetSettings {
  static readonly type = '[Settings] GetSettings';
  constructor(readonly payload: { retailerId: string }) {}
}

export class SaveSettings {
  static readonly type = '[Settings] SaveSettings';
  constructor(readonly payload: Partial<Settings>) {}
}

export class AddWastageType {
  static readonly type = '[Settings] AddWastageType';
  constructor(readonly payload: CreateWastageTypeRequest) {}
}

export class UpdateWastageType {
  static readonly type = '[Settings] UpdateWastageType';
  constructor(readonly payload: UpdateWastageTypeRequest) {}
}

export class RemoveWastageType {
  static readonly type = '[Settings] RemoveWastageType';
  constructor(readonly payload: Pick<WastageType, 'id'>) {}
}

export class AddRecipeCategory {
  static readonly type = '[Settings] AddRecipeCategory';
  constructor(readonly payload: CreateRecipeCategoryRequest) {}
}
export class UpdateRecipeCategory {
  static readonly type = '[Settings] UpdateRecipeCategory';
  constructor(readonly payload: UpdateRecipeCategoryRequest) {}
}

export class RemoveRecipeCategory {
  static readonly type = '[Settings] RemoveRecipeCategory';
  constructor(readonly payload: RemoveRecipeCategoryRequest) {}
}

export class AddAccountingCategory {
  static readonly type = '[Settings] AddAccountingCategory';
  constructor(readonly payload: CreateAccountingCategoryRequest) {}
}

export class UpdateAccountingCategory {
  static readonly type = '[Settings] UpdateAccountingCategory';
  constructor(readonly payload: UpdateAccountingCategoryRequest) {}
}

export class RemoveAccountingCategory {
  static readonly type = '[Settings] RemoveAccountingCategory';
  constructor(readonly payload: Pick<AccountingCategory, 'id'>) {}
}

export class GetSalesTypes {
  static readonly type = '[SalesType] GetSalesTypes';
}

export class CreateSalesType {
  static readonly type = '[SalesType] Create';
  constructor(
    public payload: SalesTypeBase,
    public retailerId: string,
  ) {}
}

export class UpdateSalesType {
  static readonly type = '[SalesType] UpdateSalesType';
  constructor(
    public payload: SalesType,
    public retailerId: string,
  ) {}
}

export class DeleteSalesType {
  static readonly type = '[SalesType] DeleteSalesType';
  constructor(
    public payload: { id: string },
    public retailerId: string,
  ) {}
}

export class MakeDefaultSalesType {
  static readonly type = '[SalesType] MakeDefaultSalesType';
  constructor(
    public payload: { id: string },
    public retailerId: string,
  ) {}
}
