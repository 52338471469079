"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DomainEnum = void 0;
var DomainEnum;
(function (DomainEnum) {
  DomainEnum["Accounting"] = "Accounting";
  DomainEnum["Administration"] = "Administration";
  DomainEnum["Analytics"] = "Analytics";
  DomainEnum["CentralKitchen"] = "CentralKitchen";
  DomainEnum["Integration"] = "Integration";
  DomainEnum["Inventory"] = "Inventory";
  DomainEnum["Recipe"] = "Recipe";
  DomainEnum["Procurement"] = "Procurement";
  DomainEnum["TestDomain"] = "TestDomain";
})(DomainEnum = exports.DomainEnum || (exports.DomainEnum = {}));
