import { CheckPermissionAllowance, CheckResourcesRequest } from '../../core';
import { PermissionsStateMetadata } from '../state';

export class CheckAccessMany {
  static readonly type = '[Permissions] CheckAccessManyRequest';
  constructor(
    readonly payload: CheckResourcesRequest,
    readonly forceFetch?: boolean,
  ) {}
}

export class IsAllowed {
  static readonly type = '[Permissions] IsAllowedRequest';
  constructor(readonly payload: CheckPermissionAllowance) {}
}

export class PatchPermissionsMetadata {
  static readonly type = '[Permissions] PatchPermissionsMetadata';
  constructor(readonly payload: Partial<PermissionsStateMetadata>) {}
}
