"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PermissionPolicyActionEnum = void 0;
var PermissionPolicyActionEnum;
(function (PermissionPolicyActionEnum) {
  PermissionPolicyActionEnum["Create"] = "create";
  PermissionPolicyActionEnum["Update"] = "update";
  PermissionPolicyActionEnum["View"] = "view";
  PermissionPolicyActionEnum["Disable"] = "disable";
  PermissionPolicyActionEnum["ViewList"] = "view:list";
})(PermissionPolicyActionEnum = exports.PermissionPolicyActionEnum || (exports.PermissionPolicyActionEnum = {}));
