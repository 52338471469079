"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RetailerActionEnum = void 0;
var RetailerActionEnum;
(function (RetailerActionEnum) {
  RetailerActionEnum["Create"] = "create";
  RetailerActionEnum["Update"] = "update";
  RetailerActionEnum["View"] = "view";
  RetailerActionEnum["UpdatePlan"] = "update:plan";
  RetailerActionEnum["UpdateSettings"] = "update:settings";
})(RetailerActionEnum = exports.RetailerActionEnum || (exports.RetailerActionEnum = {}));
