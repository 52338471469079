"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InventoryReportActionEnum = exports.FinancialReportActionEnum = exports.ProcurementReportActionEnum = void 0;
var ProcurementReportActionEnum;
(function (ProcurementReportActionEnum) {
  ProcurementReportActionEnum["GenerateRetailer"] = "generate:retailer";
  ProcurementReportActionEnum["Generate"] = "generate";
})(ProcurementReportActionEnum = exports.ProcurementReportActionEnum || (exports.ProcurementReportActionEnum = {}));
var FinancialReportActionEnum;
(function (FinancialReportActionEnum) {
  FinancialReportActionEnum["GenerateRetailer"] = "generate:retailer";
  FinancialReportActionEnum["Generate"] = "generate";
})(FinancialReportActionEnum = exports.FinancialReportActionEnum || (exports.FinancialReportActionEnum = {}));
var InventoryReportActionEnum;
(function (InventoryReportActionEnum) {
  InventoryReportActionEnum["GenerateRetailer"] = "generate:retailer";
  InventoryReportActionEnum["Generate"] = "generate";
})(InventoryReportActionEnum = exports.InventoryReportActionEnum || (exports.InventoryReportActionEnum = {}));
