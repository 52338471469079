"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RetailerItemActionEnum = void 0;
var RetailerItemActionEnum;
(function (RetailerItemActionEnum) {
  RetailerItemActionEnum["Create"] = "create";
  RetailerItemActionEnum["UpdateDetails"] = "update:details";
  RetailerItemActionEnum["UpdateLocations"] = "update:locations";
  RetailerItemActionEnum["UpdateState"] = "update:state";
  RetailerItemActionEnum["View"] = "view";
  RetailerItemActionEnum["ViewList"] = "view:list";
  RetailerItemActionEnum["Merge"] = "merge";
  RetailerItemActionEnum["Lock"] = "lock";
})(RetailerItemActionEnum = exports.RetailerItemActionEnum || (exports.RetailerItemActionEnum = {}));
