"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InventoryItemActionEnum = void 0;
var InventoryItemActionEnum;
(function (InventoryItemActionEnum) {
  InventoryItemActionEnum["View"] = "view";
  InventoryItemActionEnum["UpdateCosting"] = "update:costing";
  InventoryItemActionEnum["UpdateStorage"] = "update:storage";
})(InventoryItemActionEnum = exports.InventoryItemActionEnum || (exports.InventoryItemActionEnum = {}));
